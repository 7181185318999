import { type PropsWithChildren, createContext, useContext } from "react";
import type { ENV } from "~/env";

const EnvContext = createContext<ENV | null>(null);

type Props = PropsWithChildren<{
	env: ENV;
}>;

export function EnvContextProvider({ env, children }: Props) {
	return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
}

export default function useEnvContext() {
	const context = useContext(EnvContext);
	if (context === null) {
		throw new Error("useEnvContext must be used within a EnvContextProvider");
	}
	return context;
}
